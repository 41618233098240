import { addDataToQueryString } from '@/helpers/strings'
import api from './api'
import { PaginatedData, PaginationReq, ServerRes } from './api.types'
import { GROUP_MEMBERS, MEMBER_INVITE, TEAM_MEMBER } from './endpoints'

interface Receiver {
  id: number
  type: string
  name: string
  img: string
  email: string
  phone_number: string
  num_connections: number
}

interface Sender {
  id: number
  type: string
  name: string
  img: null
  email: string
  phone_number: string
  num_connections: number
}
interface ConnectionResDTO {
  id: number
  sender: Sender
  receiver: Receiver
  status: string
  message: string
}

export type Member = {
  id: number
  first_name: string
  last_name: string
  avatar: string
  about: string
  num_connections: number
  connection: ConnectionResDTO
  // is_founder: boolean
  user_id: number
  is_active: boolean
  status?: 'Active' | 'Disabled'
  position?: string
  startup_logo?: string
  startup_name?: string
}
export type MemberInviteReqDTO = {
  recipient_email: string
  startup: number
}
export type MemberInviteResDTO = {
  id: number
  recipient_email: string
  status: string
  expires: string
  created: string
  updated: string
  startup: number
}
export interface GroupMembersResDTO {
  id: number
  members: GroupMember[]
  num_connections: string
  connection: ConnectionResDTO
  member: string
  category: string
  name: string
  logo: string
  about: string
  structure: string
  registration_id: string
  address: string
  email: string
  phone_number: string
  website: string
  created: string
  updated: string
}

export interface GroupMember {
  id: number
  user_id: string
  first_name: string
  last_name: string
  about: string
  email: string
  phone_number: string
  date_joined: string
  group: number
  role: {
    id: number
    name: string
  }
  status: string
  is_accepted: boolean
}

export const TeamMembers = async (data: PaginationReq) => {
  const qs = addDataToQueryString('', data)
  return (
    await api.get<ServerRes<PaginatedData<Member>>>(
      TEAM_MEMBER + (qs ? `?${qs}` : '')
    )
  ).data.data
}

export const ModifyFounder = async (data: {
  member: number
  founder?: boolean
  startup: number
  default?: boolean
  position?: string
  status?: 'Active' | 'Inactive'
}) => {
  return await api.patch(TEAM_MEMBER + `${data.member}/`, {
    startup: data.startup,
    ...(typeof data.founder === 'boolean' ? { is_founder: data.founder } : {}),
    ...(data.default ? { default: data.default } : {}),
    ...(data.position ? { position: data.position } : {}),
    ...(data.status ? { status: data.status } : {}),
  })
}

export const ActivateOrDeactivateMember = async (data: { member: number }) => {
  return await api.post(TEAM_MEMBER + `${data.member}/deactivate-activate/`, {})
}

export const InviteMember = async (data: MemberInviteReqDTO) => {
  return await api.post<ServerRes<MemberInviteResDTO>>(MEMBER_INVITE, data)
}

export const GroupMembers = async (data: { id: number }) => {
  return (await api.get<ServerRes<GroupMembersResDTO>>(GROUP_MEMBERS + data.id))
    .data.data
}
