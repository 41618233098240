import { AuthUser, StartupInfoReqDTO } from '@/apis/authApis'
import Checkbox from '@/common/Input/Checkbox'
import Datalist from '@/common/Input/Datalist'
import FormGroup from '@/common/Input/FormGroup'
import InputWithAdornment from '@/common/Input/InputWithAdornment'
import SelectInput from '@/common/Input/SelectInput'
import Textarea from '@/common/Input/Textarea'
import Typography from '@/common/Typography'
import Button from '@/common/button/Button'
import { useCountries } from '@/hooks/useCountryStates'
import { useCreateStartup } from '@/store/authStore'
import { FormikHelpers, useFormik } from 'formik'
import { startupSchema } from '../validators/signup.validator'
import { errorHandler, withAsync } from '@/helpers/withAsync'
import { useGetCountries, useGetIndustries } from '@/store/dataStore'
import { ucword } from '@/helpers/strings'
import { ToastNotify } from '@/common/toastManager'

const StartupInformation = (props: { data: Partial<AuthUser> }) => {
  const [Countries] = useCountries()
  const { data: serverCountries, isLoading: isLoadingCountries } =
    useGetCountries()
  const { data: Industries, isLoading: isLoadingIndustries } =
    useGetIndustries()
  const { mutateAsync: createStartupAction, isLoading: isCreatingAccount } =
    useCreateStartup()
  const INITIAL_VALUES: StartupInfoReqDTO = {
    about_us: '',
    country: 'Nigeria',
    industry: '',
    is_registered: false,
    name: '',
    website: '',
  }
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    touched,
    handleSubmit,
    isValid,
    isSubmitting,
  } = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: submitHandler,
    validationSchema: startupSchema,
  })
  async function submitHandler(
    values: StartupInfoReqDTO,
    { setSubmitting, resetForm }: FormikHelpers<StartupInfoReqDTO>
  ) {
    if (!props.data?.access) {
      ToastNotify('error', {
        message: 'Token Expired, kindly start your registeration again.',
      })
      return
    }
    const { error, response } = await withAsync(() => {
      return createStartupAction({ ...values, access: props.data.access! })
    })

    if (error) {
      errorHandler(error)
      return setSubmitting(false)
    }
    if (response) {
      setSubmitting(false)
      resetForm()
      ToastNotify('info', {
        title: 'Account Created.',
        content:
          'A message has been sent to your email, kindly go and verify your email to complete account creation.',
      })
    }
  }

  return (
    <main className='flex flex-col items-start gap-6 self-stretch'>
      <Typography as='h2' heading='sm' className='text-sifuse-shades-900'>
        Business Information
      </Typography>
      <form
        onSubmit={handleSubmit}
        className='flex flex-col items-start gap-4 self-stretch md:grid grid-cols-2'
      >
        <FormGroup
          label='Business Name'
          id='startup_name'
          className='col-span-2'
        >
          <InputWithAdornment
            placeholder='Enter Business name'
            autoComplete='organization'
            onChange={handleChange}
            onBlur={handleBlur}
            name='name'
            value={values.name}
            error={
              errors.name && (touched.name as unknown as string) && errors.name
            }
          />
        </FormGroup>

        <FormGroup id='regis' className='col-span-2 !gap-0'>
          <div className='flex justify-start items-center gap-1.5'>
            <Checkbox
              id='registered'
              onBlur={handleBlur}
              onChange={(e) => {
                setFieldValue('is_registered', e.currentTarget.checked)
              }}
              name='is_registered'
              checked={values.is_registered}
            />
            <Typography
              as='label'
              label='md'
              htmlFor='registered'
              className='text-sifuse-shades-900 cursor-pointer'
            >
              Is your company registered ?
            </Typography>
          </div>
        </FormGroup>
        <FormGroup label='About us' id='about_us' className='col-span-2'>
          <Textarea
            className='resize-none'
            rows={5}
            onChange={handleChange}
            onBlur={handleBlur}
            name='about_us'
            value={values.about_us}
            error={
              errors.about_us &&
              (touched.about_us as unknown as string) &&
              errors.about_us
            }
          />
        </FormGroup>
        <FormGroup label='Industry' id='industry' className='col-span-2'>
          <Datalist
            loading={isLoadingIndustries}
            options={(Industries || []).sort((a, b) => a.localeCompare(b))}
            extractValueFromInput
            placeholder='Select or enter industry'
            className='max-w-[50px]'
            onChange={handleChange}
            onBlur={handleBlur}
            name='industry'
            value={values.industry}
            error={
              errors.industry &&
              (touched.industry as unknown as string) &&
              errors.industry
            }
          />
        </FormGroup>
        <FormGroup id='website_url' label='Website  URL' className='col-span-1'>
          <InputWithAdornment
            placeholder='http(s)://'
            inputMode={'url'}
            autoComplete='url'
            onChange={handleChange}
            onBlur={handleBlur}
            name='website'
            value={values.website}
            error={
              errors.website &&
              (touched.website as unknown as string) &&
              errors.website
            }
          />
        </FormGroup>
        <FormGroup id='country' label='Country/Location' className='col-span-1'>
          <SelectInput
            loading={isLoadingCountries}
            options={(serverCountries || [])
              .map((country) =>
                Countries.find(
                  (_country: any) =>
                    _country.name.toLowerCase() === country.toLowerCase()
                )
              )
              .filter((el) => el)}
            menuClassName=' !mr-0 overflow-x-hidden whitespace-normal !left-auto right-0'
            optionsText={(country) =>
              country?.emoji ? (
                <span className='flex items-center gap-2.5'>
                  <span>{country.flag}</span>
                  <span className='text-sifuse-shades-600 text-sm leading-5'>
                    {country.name}
                  </span>
                </span>
              ) : (
                'Country'
              )
            }
            optionsValue={(country) => ucword(country?.name || '')}
            placeholder='Select Country'
            className='w-full'
            onChange={handleChange}
            onBlur={handleBlur}
            name='country'
            value={values.country}
            error={
              errors.country &&
              (touched.country as unknown as string) &&
              errors.country
            }
          />
        </FormGroup>
        {/* <FormGroup id='founder' className='col-span-2 !gap-0'>
          <div className='flex justify-start items-center gap-1.5'>
            <Checkbox id='is_founder' />
            <Typography
              as='label'
              label='md'
              htmlFor='is_founder'
              className='text-sifuse-shades-900 cursor-pointer'
            >
              Are you the founder ?
            </Typography>
          </div>
        </FormGroup> */}

        <Button
          type='submit'
          color='primary'
          size='xl'
          className='w-full text-center  mt-8 col-span-2'
          loading={isCreatingAccount || isSubmitting}
          disabled={!isValid || isSubmitting || isCreatingAccount}
        >
          Sign Up
        </Button>
      </form>
    </main>
  )
}

export default StartupInformation
