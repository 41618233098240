import React, { useMemo } from 'react'

import { FaCircle, FaHeart, FaRegHeart } from 'react-icons/fa'
import moment from 'moment'
import Button from '@/common/button/Button'
import classNames from 'classnames'

import { COMMENTS, POST, POSTS } from '@/constants/querryKeys'

import LoadingState from '../LoadingState'
import Typography from '@/common/Typography'
import PosterDetails from '../PosterDetails'

import { InfiniteScroll, Ref } from '../utils/types'

import Modal, { RefType } from '@/common/Modal'

import TextInput from '@/common/TextInput'

import { ReactComponent as ShareIcon } from '@/assets/network/share.svg'
import { ReactComponent as ShareIconHighlighted } from '@/assets/network/share-highlighted.svg'
import { ReactComponent as CommentIcon } from '@/assets/network/green comment.svg'
import { ReactComponent as PrevIcon } from '@/assets/network/prev.svg'
import { ReactComponent as NextIcon } from '@/assets/network/next.svg'
import { numberFormatter } from '@/helpers/numberFormatter'
import { copyToClipBoard } from '../utils/copyToClipBoard'
import {
  useGetComments,
  useGetPost,
  usePostComment,
  useToggleLike,
} from '@/store/networkStore'

import InfiniteScrollContainer from '@/common/InfiniteScrollContainer'

import { isImg } from '../../../../helpers/isImg'
import { TimelineResultsStructure } from '@/apis/networkApis'
import { formatUserDetails } from '../../../../helpers/formatUserDetails'
import Permission from '@/common/permissions/Permission'
import { PermissionNames } from '@/common/permissions/permission.constant'

interface MediaProps {
  clickedIndx: number
  created: Date
  media: {
    id: number
    post: number
    file: string
    metadata: Record<string, any>
  }[]
}

interface Prop {
  id: number | null | undefined
}

const Carousel = ({ media = [], created, clickedIndx }: MediaProps) => {
  const [currentIndex, setCurrentIndex] = React.useState<number>(clickedIndx)

  const nextImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === media.length - 1 ? 0 : prevIndex + 1
    )
  }

  const prevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? media.length - 1 : prevIndex - 1
    )
  }

  return (
    <div className='p-4 flex relative flex-col items-start gap-2 justify-start w-full h-full'>
      <span
        onClick={prevImage}
        className='cursor-pointer absolute top-[40%] -left-6 z-10 md:left-10'
        style={{
          zIndex: 1000,
        }}
      >
        <PrevIcon />
      </span>
      <div
        className='w-full h-full flex relative'
        style={{
          overflow: 'hidden',
        }}
      >
        {media.map((medi, idx) =>
          !isImg(medi.file) ? (
            <video
              key={medi.id}
              controls
              controlsList='nodownload nofullscreen noremoteplayback'
              muted
              style={{
                animationFillMode: 'forwards',
                zIndex: 100,
              }}
              className={classNames(
                'object-center object-cover w-full h-[95%] rounded-md opacity-0 z-9',

                {
                  'animate-fadeIn': idx === currentIndex,
                }
              )}
              src={medi?.file}
            />
          ) : (
            <img
              key={medi.id}
              src={medi?.file}
              alt={`post_img_${medi?.id + 1}`}
              style={{
                animationFillMode: 'forwards',
              }}
              className={classNames(
                'w-full h-[95%] rounded-md absolute opacity-0 object-center object-cover',
                {
                  'animate-fadeIn': idx === currentIndex,
                }
              )}
            />
          )
        )}
      </div>

      <span
        onClick={nextImage}
        className='cursor-pointer absolute top-[40%] -right-6 md:right-10'
        style={{
          zIndex: 1000,
        }}
      >
        <NextIcon />
      </span>

      <span className='opacity-50 flex gap-2 items-center'>
        <FaCircle fontSize={6} />
        <p> {moment(created).format('hh:mm A MMMM Do, YYYY')}</p>
      </span>
    </div>
  )
}
const LikeComponent = ({
  like,
  id,
  numLikes,
  likePost,
}: {
  like: boolean
  id: number
  numLikes: number
  likePost: (obj: any) => void
}) => {
  const [isLiked, setisLiked] = React.useState<boolean>(false)

  useMemo(() => {
    setisLiked(like)
  }, [like])
  return (
    <Permission
      permissions={[PermissionNames['Can Manage Network']]}
      noAccess={
        <Button
          className='rounded-md px-2 sm:px-6 border-none'
          startIcon={
            isLiked ? (
              <FaHeart className={isLiked ? 'text-red-500' : ''} />
            ) : (
              <FaRegHeart />
            )
          }
          size='sm'
          color='plain'
        >
          <span
            className={
              isLiked
                ? 'text-red-500 flex items-center justify-center gap-1'
                : 'flex items-center justify-center gap-1'
            }
          >
            {numberFormatter(numLikes)}
            <span className='hidden sm:block'>
              {numLikes > 1 ? 'Likes' : 'Like'}
            </span>
          </span>
        </Button>
      }
    >
      <Button
        className='rounded-md px-2 sm:px-6 border-none'
        startIcon={
          isLiked ? (
            <FaHeart className={isLiked ? 'text-red-500' : ''} />
          ) : (
            <FaRegHeart />
          )
        }
        size='sm'
        color='plain'
        onClick={() => {
          setisLiked((prev) => !prev)
          likePost({ id: id as number })
        }}
      >
        <span
          className={
            isLiked
              ? 'text-red-500 flex items-center justify-center gap-1'
              : 'flex items-center justify-center gap-1'
          }
        >
          {numberFormatter(numLikes)}
          <span className='hidden sm:block'>
            {numLikes > 1 ? 'Likes' : 'Like'}
          </span>
        </span>
      </Button>
    </Permission>
  )
}

function SinglePost({ id }: Prop) {
  const modalRef1 = React.useRef<RefType>(null)

  const [comment, setcomment] = React.useState<string>('')

  const [clickedMedia, setclickedMedia] = React.useState<number>(0)
  const [isTextCopied, setisTextCopied] = React.useState<boolean>(false)

  const toggleModal1 = (clickedIndx: number): void => {
    setclickedMedia(clickedIndx)
    modalRef1?.current?.handleToggle()
  }

  //comment on post
  const {
    mutate: postComment,
    isLoading: commentLoading,
    isSuccess: postCommentSuccess,
  } = usePostComment({
    invalidateKeys: [[COMMENTS, id], [POST, id], [POSTS]],
    cb: () => setcomment(''),
  })

  //toggle like
  const { mutate: likePost, isLoading: likeLoading } = useToggleLike({
    invalidateKeys: [[POST, id], [POSTS]],
  })

  //get post with id
  const {
    data: post,
    isLoading: postLoading,
    isError: isPostError,
  } = useGetPost({
    id: id as number,
  })

  //get comments
  const {
    data: comments,
    isLoading: commentsLoading,
    isError: isCommentsError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetComments({ postId: id as number })

  const commentsData = (comments as unknown as InfiniteScroll)?.pages
  const iscommentsEmpty = commentsData?.[0].data?.data?.results?.length < 1
  const commentsCount = commentsData?.[0].data?.data?.count || 0
  const postData = post as unknown as TimelineResultsStructure

  return (
    <>
      {
        //if first load
        postLoading || isPostError ? (
          <LoadingState
            condition={postLoading ? 'isLoading' : isPostError ? 'isError' : ''}
          />
        ) : (
          <div
            className={classNames(
              'bg-[white] flex gap-2 flex-col rounded-md px-1 py-3 w-full md:px-3'
            )}
          >
            <Typography heading='xs'>Post on Timeline</Typography>
            <div className='flex items-center justify-between'>
              <PosterDetails {...formatUserDetails(postData)} />
            </div>
            <p dangerouslySetInnerHTML={{ __html: postData?.content }} />

            {/* img or video grid goes here */}
            <div className='grid grid-cols-1  gap-2 sm:grid-cols-2 '>
              {postData.media.map((media, idx) => (
                <span key={media.id} onClick={() => toggleModal1(idx)}>
                  {!isImg(media.file) ? (
                    <video
                      controls
                      controlsList='nodownload nofullscreen noremoteplayback'
                      muted
                      className='object-center object-cover w-[374px] h-[200px] cursor-pointer'
                      src={media.file}
                    />
                  ) : (
                    <img
                      src={media.file}
                      alt='post_img'
                      className='object-center object-cover w-[374px] h-[200px] cursor-pointer'
                      onClick={() => toggleModal1(idx)}
                    />
                  )}
                </span>
              ))}
            </div>

            <span className='opacity-50 flex gap-2 items-center'>
              <FaCircle fontSize={6} />
              <p> {moment(postData.created).format('hh:mm A MMMM Do, YYYY')}</p>
            </span>

            <div className='flex justify-between w-full items-center'>
              <div className='flex items-center gap-2'>
                <div className='flex items-center gap-1 text-green-600 text-sm font-medium'>
                  <LikeComponent
                    id={id!}
                    numLikes={postData.num_likes}
                    like={postData.like}
                    likePost={likePost}
                  />
                  <CommentIcon />
                  {numberFormatter(postData.num_comments)}
                  <span className='hidden sm:block'>
                    {postData.num_comments > 1 ? 'Comments' : 'Comment'}
                  </span>
                </div>
                <Button
                  className={'!border-0'}
                  size='sm'
                  color='white'
                  startIcon={
                    isTextCopied ? <ShareIconHighlighted /> : <ShareIcon />
                  }
                  onClick={() =>
                    !isTextCopied &&
                    copyToClipBoard(
                      `${process.env.REACT_APP_SIFUSE_FRONTEND_URL}/app/networks?post=${id}`,
                      () => setisTextCopied(true)
                    )
                  }
                >
                  {isTextCopied ? (
                    <span className='text-[#F68511] font-medium'>
                      Link copied
                    </span>
                  ) : (
                    <span className='font-medium'> Copy link</span>
                  )}
                </Button>
              </div>
            </div>
            <Typography heading='2xs' className='mt-2'>
              Comments {`(${numberFormatter(commentsCount)})`}
            </Typography>
            <Permission permissions={[PermissionNames['Can Manage Network']]}>
              <div className='flex flex-col gap-2 items-start w-full'>
                <TextInput
                  onChange={(e) => setcomment(e.target.value)}
                  value={comment}
                  fieldType='textarea'
                  rows='4'
                  placeholder='Start typing...'
                  className='!w-full border-gray-300 rounded-lg bg-black-white-shades-1'
                  containerClass='!w-full'
                />
                <Button
                  onClick={() =>
                    postComment({ id: id as number, content: comment })
                  }
                  loading={commentLoading}
                  className='self-end'
                  size='sm'
                >
                  Post comment
                </Button>
              </div>
            </Permission>

            {
              //if first load

              <LoadingState
                condition={
                  commentsLoading
                    ? 'isLoading'
                    : iscommentsEmpty
                    ? 'isEmpty'
                    : isCommentsError
                    ? 'isError'
                    : ''
                }
                isEmptyDisplay={
                  <>
                    <Typography heading='3xs'>No Comments</Typography>
                  </>
                }
              />
            }
            <InfiniteScrollContainer
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              isFetchingNextPage={isFetchingNextPage}
              containerClass='!min-h-[5vh]'
            >
              <div className='flex-col flex gap-2'>
                {commentsData?.map((page) =>
                  page?.data.data?.results?.map(
                    (comment: TimelineResultsStructure) => (
                      <div key={comment.id}>
                        <PosterDetails {...formatUserDetails(comment)} />
                        <p
                          dangerouslySetInnerHTML={{ __html: comment?.content }}
                        />
                        <span className='opacity-50 flex gap-2 items-center'>
                          <FaCircle fontSize={6} />
                          <p>
                            {' '}
                            {moment(comment.created).format(
                              'hh:mm A MMMM Do, YYYY'
                            )}
                          </p>
                        </span>
                      </div>
                    )
                  )
                )}
              </div>
            </InfiniteScrollContainer>
          </div>
        )
      }
      <Modal ref={modalRef1} modalClass='w-[90vw] h-[636px] sm:w-[800px]  pt-2'>
        <Carousel
          media={postData?.media}
          created={postData?.created}
          clickedIndx={clickedMedia}
        />
      </Modal>
    </>
  )
}

export default SinglePost
