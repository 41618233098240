import Typography from '@/common/Typography'
import Button from '@/common/button/Button'
import React from 'react'
import { FaEnvelope } from 'react-icons/fa'
import { ReactComponent as Followers } from '@/assets/network/programming-arrows.svg'

import { imagesUrl } from '@/assets/images/imageUrls'
import { InvestorsStructure } from '../SingleUser'

import { USER } from '@/constants/querryKeys'
import { useJoinUserGroup } from '@/store/networkStore'
import { useMessageRecipient } from '@/pages/message/hooks'

import { useParams } from 'react-router'
import ConnectionBtn from '@/common/ConnectionBtn'
import Permission from '@/common/permissions/Permission'
import { PermissionNames } from '@/common/permissions/permission.constant'

function SingleInvestorDesc({
  id,
  email,
  first_name,
  last_name,
  phone_number,
  user_type,
  avatar,
  about,
  last_login,
  date_joined,
  email_verified,
  num_connections,
  connection,
  connectionReqType = 'investorgroup',
}: InvestorsStructure & { connectionReqType?: string }) {
  const messageUser = useMessageRecipient()

  const { id: pgId } = useParams()
  const startupId = Number(pgId)
  //join user froup
  const { mutateAsync: joinUserGroup, isLoading: joinUserGroupLoading } =
    useJoinUserGroup()
  return (
    <>
      <div className='bg-[white] p-4  rounded-lg flex flex-col gap-2 items-start w-full sm:flex-row sm:justify-between sm:items-center'>
        <div className='flex gap-2 flex-col justify-start sm:flex-row'>
          <img
            src={avatar || imagesUrl.blanckProfileImg}
            alt='start_up_logo'
            className='h-[129px] w-[129px] object-center object-cover rounded-full'
          />
          <div className='flex flex-col gap-1 px-2 sm:px-3'>
            <Typography heading='2xs'>{`${first_name || ''} ${
              last_name || ''
            }`}</Typography>
            <small className='font-light'>{about}</small>
            <Permission permissions={[PermissionNames['Can Manage Network']]}>
              <div className='flex gap-2 mt-4 sm:gap-4'>
                <ConnectionBtn
                  createConnectionObj={{
                    id,
                    type: connectionReqType,
                  }}
                  invalidateKey={[USER, id]}
                  connection={connection! as any}
                />

                {!!connection && connection.status !== 'Pending' && (
                  <Button
                    className='rounded px-4 border'
                    color='white'
                    startIcon={<FaEnvelope />}
                    size='sm'
                    onClick={() => messageUser(id, 'user')}
                  >
                    Message
                  </Button>
                )}
              </div>
            </Permission>
          </div>
        </div>

        <div className='flex flex-row gap-2 sm:flex-col'>
          <span className='bg-[#f3f3f8] flex gap-2 items-center p-2 rounded-md'>
            <Followers />
            <span className='flex gap-1 font-normal'>
              <strong>{num_connections}</strong>Connected
            </span>
          </span>
        </div>
      </div>
    </>
  )
}

export default SingleInvestorDesc
