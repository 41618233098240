import { imagesUrl } from '@/assets/images/imageUrls'
import Typography from '@/common/Typography'
import Button from '@/common/button/Button'
import { STARTUP } from '@/constants/querryKeys'
import { useMessageRecipient } from '@/pages/message/hooks'
import { ReactComponent as AddUser } from '@/assets/icons/utils/addUser.svg'
import { useCreateConnection, useDeleteConnection } from '@/store/networkStore'
import React from 'react'
import { FaCheck, FaCheckSquare, FaEnvelope, FaPlus } from 'react-icons/fa'
import { FaX } from 'react-icons/fa6'

import { useLocation, useNavigate, useParams } from 'react-router'
import { Pages } from '@/routes/route.constant'
import ConnectionBtn from '@/common/ConnectionBtn'
import { MembersType } from '@/apis/networkApis'
import clsx from 'clsx'
import Permission from '@/common/permissions/Permission'
import { PermissionNames } from '@/common/permissions/permission.constant'

function TeamInfoCard({
  id,
  email,
  first_name,
  last_name,
  phone_number,
  user_type,
  email_verified,
  date_joined,
  last_login,
  connection,
  about,
  avatar,
  user_id,
}: MembersType) {
  const navigate = useNavigate()
  const messageUser = useMessageRecipient()
  const { state } = useLocation()
  const startupId = Number(state?.id)

  return (
    <div className='flex w-[131px] h-[213px] gap-1 flex-col items-center justify-between  sm:gap-3'>
      <img
        src={avatar || imagesUrl.blanckProfileImg}
        alt='start_up_logo'
        className='h-[91.25px] w-[91.25px] object-center object-cover rounded-full '
      />
      <div className='flex flex-col gap-2 px-2 py-0 sm:px-3 sm:gap-3 w-full'>
        <div className='flex flex-col gap-0 items:start sm:items-center justify-center'>
          <Typography
            label='sm'
            onClick={() =>
              navigate(
                `/app/networks/${Pages.User}/${`${first_name} ${last_name}`}`,
                {
                  state: {
                    id: user_id,
                  },
                }
              )
            }
            className='cursor-pointer hover:shadow-sm '
          >
            <strong className='font-medium text-[14px]'>
              {' '}
              {`${first_name} ${last_name}`}
            </strong>
          </Typography>

          <small className={`font-light`}>{about || '-'}</small>
        </div>
        <Permission permissions={[PermissionNames['Can Manage Network']]}>
          <div
            className={clsx('flex items-center justify-between w-full mt-1', {
              '!justify-center':
                !connection || connection?.status === 'Pending',
            })}
          >
            <ConnectionBtn
              createConnectionObj={{
                id: user_id!,
                type: 'user',
              }}
              invalidateKey={[STARTUP, startupId]}
              connection={connection! as any}
              connectionElement={
                <Button
                  color='white'
                  className='rounded w-[40px] h-[32px] border'
                  startIcon={<FaPlus />}
                  size='sm'
                ></Button>
              }
              acceptConnectionElement={
                <Button
                  color='green'
                  className='rounded w-[40px] h-[32px] border'
                  startIcon={<FaCheck />}
                  size='sm'
                ></Button>
              }
              acceptedConnectionElement={
                <Button
                  color='lightgreen'
                  className='rounded w-[40px] h-[32px] border'
                  startIcon={<FaCheck />}
                  size='sm'
                ></Button>
              }
              deleteConnectionElement={
                <Button
                  color='lightred'
                  startIcon={<FaX />}
                  className='rounded w-[40px] h-[32px] border'
                  size='sm'
                ></Button>
              }
            />
            {!!connection && connection.status !== 'Pending' && (
              <Button
                className='rounded w-[40px] h-[32px] border'
                color='white'
                onClick={() => messageUser(+user_id!, 'user')}
              >
                <FaEnvelope />
              </Button>
            )}
          </div>
        </Permission>
      </div>
    </div>
  )
}

export default TeamInfoCard
