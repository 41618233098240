import { ConnectionResDTO } from '@/apis/networkApis'
import Button from '@/common/button/Button'
import Modal, { RefType } from '@/common/Modal'
import TextInput from '@/common/TextInput'
import { useAuthContext } from '@/contexts/AuthContextProvider'
import {
  useChangeConnectionStatus,
  useCreateConnection,
  useDeleteConnection,
} from '@/store/networkStore'
import clsx from 'clsx'
import React, { useState } from 'react'

interface Props {
  connection: null | ConnectionResDTO
  invalidateKey: (string | number | any)[]
  createConnectionSx?: string
  unConnectSx?: string
  deleteSx?: string
  createConnectionObj: {
    id: number
    type: 'user' | 'startup' | 'investorgroup' | 'entity' | string
  }
  connectionElement?: React.ReactElement
  acceptConnectionElement?: React.ReactElement
  deleteConnectionElement?: React.ReactElement
  acceptedConnectionElement?: React.ReactElement
}
function ConnectionBtn({
  connection,
  invalidateKey,
  createConnectionObj,
  createConnectionSx,
  unConnectSx,
  deleteSx,
  connectionElement,
  acceptConnectionElement,
  acceptedConnectionElement,
  deleteConnectionElement,
}: Props) {
  const modalRef = React.useRef<RefType>(null)
  const { currentAccount } = useAuthContext()
  // delete connection
  const { mutate: deleteConnection, isLoading: deleteConnectionLoading } =
    useDeleteConnection({
      invalidateKey: [...invalidateKey],
    })

  //accept Connection
  const { mutateAsync: acceptConnection, isLoading: acceptConnectionLoading } =
    useChangeConnectionStatus({
      invalidateKey: [...invalidateKey],
    })

  const toggleModal = (): void => {
    modalRef?.current?.handleToggle()
  }

  const isSender = +connection?.sender?.id! === +currentAccount?.id!

  let btn: React.ReactElement = <></>
  switch (connection?.status) {
    case 'Pending':
      btn = (
        <>
          {!isSender ? (
            <div className='flex gap-1 items-center'>
              {' '}
              {acceptConnectionElement ? (
                React.cloneElement(acceptConnectionElement, {
                  onClick: () =>
                    acceptConnection({ id: connection.id, status: 'Accepted' }),
                  disabled: acceptConnectionLoading,
                })
              ) : (
                <Button
                  size='xs'
                  color='green'
                  className='px-3'
                  onClick={() =>
                    acceptConnection({ id: connection.id, status: 'Accepted' })
                  }
                  loading={acceptConnectionLoading}
                >
                  Accept
                </Button>
              )}
              {deleteConnectionElement ? (
                React.cloneElement(deleteConnectionElement, {
                  onClick: () => deleteConnection({ id: connection.id }),
                  disabled: deleteConnectionLoading,
                })
              ) : (
                <Button
                  size='xs'
                  color='lightred'
                  className='px-3'
                  onClick={() => deleteConnection({ id: connection.id })}
                  loading={deleteConnectionLoading}
                >
                  Decline
                </Button>
              )}
            </div>
          ) : deleteConnectionElement ? (
            React.cloneElement(deleteConnectionElement, {
              onClick: () => deleteConnection({ id: connection.id }),
              disabled: deleteConnectionLoading,
            })
          ) : (
            <Button
              size='sm'
              color='lightred'
              className={clsx('px-4', deleteSx)}
              onClick={() => deleteConnection({ id: connection?.id })}
              loading={deleteConnectionLoading}
            >
              Delete
            </Button>
          )}
        </>
      )
      break

    case 'Accepted':
      btn = acceptedConnectionElement ? (
        React.cloneElement(acceptedConnectionElement, {
          onClick: () => deleteConnection({ id: connection.id }),
          disabled: deleteConnectionLoading,
        })
      ) : (
        <Button
          color='lightgreen'
          className={clsx('rounded  border', unConnectSx)}
          onClick={() => deleteConnection({ id: connection.id })}
          size='sm'
          loading={deleteConnectionLoading}
        >
          <span className='flex gap-2 items-center justify-center'>
            <svg
              width='16'
              height='17'
              viewBox='0 0 16 17'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M3.33301 8.97461L5.99967 11.6413L12.6663 4.97461'
                stroke='#008F5D'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            Connected
          </span>
        </Button>
      )
      break

    default:
      btn = connectionElement ? (
        React.cloneElement(connectionElement, {
          onClick: toggleModal,
        })
      ) : (
        <Button
          color='plain'
          className={clsx('rounded  border border-primary', createConnectionSx)}
          onClick={toggleModal}
          size='sm'
        >
          <span className='flex gap-2 items-center justify-center'>
            <svg
              width='9'
              height='9'
              viewBox='0 0 9 9'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4.5 0.0175781C4.71217 0.0175781 4.91566 0.101864 5.06569 0.251893C5.21571 0.401922 5.3 0.605405 5.3 0.817578V3.21758H7.7C7.91217 3.21758 8.11566 3.30186 8.26569 3.45189C8.41571 3.60192 8.5 3.8054 8.5 4.01758C8.5 4.22975 8.41571 4.43323 8.26569 4.58326C8.11566 4.73329 7.91217 4.81758 7.7 4.81758H5.3V7.21758C5.3 7.42975 5.21571 7.63323 5.06569 7.78326C4.91566 7.93329 4.71217 8.01758 4.5 8.01758C4.28783 8.01758 4.08434 7.93329 3.93431 7.78326C3.78429 7.63323 3.7 7.42975 3.7 7.21758V4.81758H1.3C1.08783 4.81758 0.884344 4.73329 0.734315 4.58326C0.584286 4.43323 0.5 4.22975 0.5 4.01758C0.5 3.8054 0.584286 3.60192 0.734315 3.45189C0.884344 3.30186 1.08783 3.21758 1.3 3.21758H3.7V0.817578C3.7 0.605405 3.78429 0.401922 3.93431 0.251893C4.08434 0.101864 4.28783 0.0175781 4.5 0.0175781Z'
                fill='#272343'
              />
            </svg>{' '}
            Connect
          </span>
        </Button>
      )

      break
  }

  return (
    <>
      {btn}
      <Modal
        ref={modalRef}
        modalClass='w-[355px] !h-fit sm:w-[800px]  pt-2 md:w-[760px] md:!h-fit lg:w-[450px] lg:!h-fit'
        title='Send connection request'
        subtitle='Include a message to connection request'
        showCloseBtn
      >
        <SendConnectionReq
          toggleModal={toggleModal}
          invalidateKey={invalidateKey}
          createConnectionObj={createConnectionObj}
        />
      </Modal>
    </>
  )
}

export default ConnectionBtn

interface ModalProps {
  toggleModal: () => void
  invalidateKey: (string | number)[]
  createConnectionObj: {
    id: number
    type: string
  }
}

const SendConnectionReq = ({
  toggleModal,
  invalidateKey,
  createConnectionObj,
}: ModalProps) => {
  const [message, setmessage] = useState('')

  // create connection
  const { mutate: createConnection, isLoading: createConnectionLoading } =
    useCreateConnection({
      invalidateKey: [...invalidateKey],
      cb: () => toggleModal(),
    })

  return (
    <div className='flex flex-col gap-5'>
      <TextInput
        onChange={(e) => setmessage(e.target.value)}
        value={message}
        fieldType='textarea'
        rows='6'
        placeholder='Enter note...'
        className='w-full'
      />
      <span className='flex gap-2'>
        <Button
          className='!w-[126px] !h-[36px]'
          onClick={() =>
            createConnection({
              receiver_type: createConnectionObj.type!,
              receiver_id: createConnectionObj.id,
              message,
            })
          }
          loading={createConnectionLoading}
        >
          Send Request
        </Button>
        <Button
          className='!w-[97px] !h-[36px]'
          color='white'
          onClick={toggleModal}
        >
          Cancel
        </Button>
      </span>
    </div>
  )
}
