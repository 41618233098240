import { AxiosProgressEvent, AxiosRequestConfig, Canceler } from 'axios'
import api from './api'
import { PaginatedData, ServerRes } from './api.types'
import {
  CREATE_DOCUMENT,
  GET_ACCESS_LIST,
  GET_DATA_ROOM,
  GET_FILES,
  GET_FOLDERS,
  GET_UPDATES,
  GET_UPDATES_COMMENTS,
  POST_UPDATES_MEDIA,
  SEARCH_DOCUMENTS,
  USERS_INVESTORS,
} from './endpoints'
import { useSanitizeQueryParams } from './hooks/useSanitizeQueryParams'

interface ApiRequestConfig extends AxiosRequestConfig {
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
  abort?: (canceler: Canceler) => void
}

export interface DocumentViewersResDTO {
  id: number
  created: Date
  about: string
  first_name: string
  last_name: string
  avatar: string
  online: boolean
  connection: null | {
    id: number
    status: 'Pending' | 'Accepted' | 'Declined'
    is_sender: boolean
  }
}

export type GetDataRoomQueryDTO = {
  page: number
  status?: 'founder' | 'member' | ''
  query?: string
  startup?: string
}
export type GetItemQueryDTO = {
  id: number
  page?: number
  status?: 'Pending' | 'Accepted' | 'Declined'
  name?: string
}

interface GrantAccessQueryDTO {
  startup?: number
  investor_group?: number
  fund_entity?: number
}

export type PostUpdatesReqDTO = {
  startup?: number
  update?: number
  content: string
}
export type PostUpdatesMediaReqDTO = {
  update: number
  file: string
}

export type GetFilesQueryDTO = {
  page: number
  folder?: number
  sort_order?: 'asc' | 'desc'
  query?: string
  startup?: string
  status?: 'Pending' | 'Accepted' | 'Declined'
  parent?: boolean
}
export type GetUsersInvestorsQueryDTO = {
  page: number
  category?: string
  search?: string
}

export interface GetDataRoomResDTO {
  id: number
  name: string
  about_us: string
  logo: string
  num_folders: number
}
export interface GetFoldersResDTO {
  id: number
  name: string
  is_folder: boolean
  file: string
  color: 'purple' | 'blue' | 'orange' | 'green' | 'primary' | 'secondary'
  created: string
  updated: string
  startup: number
  parent: number
  size: string
  file_count: number
  file_type: 'pdf' | 'docx' | 'excel'
  num_viewers: number
  files?: []
  metadata?: Record<string, any>
}
export interface CreateFolderReqDTO {
  name: string
  is_folder: boolean
  file?: File
  color?: string
  startup?: number
  parent?: number
  id?: number
  metadata?: Record<string, any>
}
export interface CreateFolderResDTO {
  id: number
  name: string
  is_folder: boolean
  file: string
  color: string
  created: string
  updated: string
  startup: number
  parent: number
}
interface Mentionedstartup {
  id: number
  name: string
  logo: string
}
interface Mentionedfund {
  id: number
  fund_manager: Fundmanager
  name: string
  structure: string
  registration_id: string
  physical_address: string
  email: string
  phone_number: string
  website: string
  about_us: string
  photo: string
  created: string
  updated: string
  user: number
}

interface Fundmanager {
  id: number
  email: string
  about: string
  first_name: string
  last_name: string
  phone_number: string
  avatar: string
}
export interface GetUpdatesResDTO {
  id: number
  // user: User
  startup: number
  content: string
  like: boolean
  num_likes: number
  num_comments: number
  media: any[]
  created: Date
  updated: Date
  user: {
    avatar: string
    name: string
    about: string
    id: number
    user_type: string
    startup: { id: number; name: string; logo: string } | null
    connection?: null | {
      id: number
      status: 'Pending' | 'Accepted' | 'Declined'
      is_sender: boolean
    }
  }
  investor_group: Mentionedstartup
  fund_entity: Mentionedfund
  business: Mentionedstartup
}

interface Media {
  id: number
  update: number
  file: string
  file_type: string
  video_cover: string
}

interface User {
  id: number
  first_name: string
  last_name: string
  avatar: string
  num_connections?: number
  about?: string
}

export interface GetAccess {
  id: number
  status?: string
  created?: string
  user: null | User
  startup?: number
  investor_group: null | {
    id: number
    logo: string
    name: string
  }
  fund_entity: null | {
    id: number
    photo: string
    name: string
  }
}

export interface UsersInvestorsResDTO {
  id: number
  data_room_access_id: number
  name: string
  img: string
  about: string
  investor_type: string
  num_connections: number
  connection: Connection
  member: Member
  has_data_room_access: 'Pending' | 'Accepted' | null
  industry_focus: null | string[]
}

interface Member {
  id: number
  group: number
  user_id: number
  first_name: string
  last_name: string
  email: string
  phone_number: string
  date_joined: string
  role: Role
  status: string
  is_accepted: boolean
}

interface Role {
  id: number
  name: string
}

interface Connection {
  id: number
  user: User1
  is_sender: boolean
  status: string
  investor_group: Investorgroup
}

interface Investorgroup {
  id: number
  name: string
  logo: string
  category: string
}

interface User1 {
  id: number
  name: string
  user_type: string
  about: string
  avatar: string
  num_connections: number
  is_founder: boolean
  startup: Startup
}

interface Startup {
  name: string
  logo: string
}

//get data room data
export const GetDataRoomData = async (data: GetDataRoomQueryDTO) => {
  return (
    await api.get<ServerRes<PaginatedData<GetDataRoomResDTO>>>(
      useSanitizeQueryParams(
        `${GET_DATA_ROOM}?page=${data.page}&query=${data.query}&status=${data.status}`
      )
    )
  ).data.data
}
//get folders
export const GetFolders = async (data: GetDataRoomQueryDTO) => {
  return (
    await api.get<ServerRes<PaginatedData<GetFoldersResDTO>>>(
      useSanitizeQueryParams(
        `${GET_FOLDERS}?page=${data.page}&startup=${data.startup}`
      )
    )
  ).data.data
}

//get single document
export const GetDocument = async (data: GetItemQueryDTO) => {
  return (
    await api.get<ServerRes<GetFoldersResDTO>>(`${CREATE_DOCUMENT}${data.id}/`)
  ).data.data
}

//get single public document
export const GetPublicDocument = async (data: GetItemQueryDTO) => {
  return (
    await api.get<ServerRes<GetFoldersResDTO>>(
      `${CREATE_DOCUMENT}${data.name}/${data.id}/`
    )
  ).data.data
}

//create document
export const CreateFile = async (payload: {
  data: CreateFolderReqDTO
  config?: ApiRequestConfig
}) => {
  return await api.post<ServerRes<CreateFolderResDTO>>(
    CREATE_DOCUMENT,
    payload.data,
    {
      ...payload.config,
    }
  )
}
//update document
export const UpddateDocument = async (
  data: Pick<CreateFolderReqDTO, 'name' | 'id'>
) => {
  return await api.patch<ServerRes<CreateFolderResDTO>>(
    CREATE_DOCUMENT + data.id + '/',
    { name: data.name }
  )
}
//delete document
export const DeleteDocument = async (data: Pick<CreateFolderReqDTO, 'id'>) => {
  return await api.delete<ServerRes<CreateFolderResDTO>>(
    CREATE_DOCUMENT + data.id + '/'
  )
}

//get files
export const GetFiles = async (data: GetFilesQueryDTO) => {
  return (
    await api.get<ServerRes<PaginatedData<GetFoldersResDTO>>>(
      useSanitizeQueryParams(
        `${GET_FILES}?page=${data.page}&folder=${data.folder}&sort_order=${data.sort_order}&startup=${data.startup}&parent=${data.parent}`
      )
    )
  ).data.data
}
//get document viewers
export const GetDocumentViewers = async (data: GetItemQueryDTO) => {
  return (
    await api.get<ServerRes<PaginatedData<DocumentViewersResDTO>>>(
      useSanitizeQueryParams(
        `${CREATE_DOCUMENT}${data.id}/viewers/?page=${data.page}`
      )
    )
  ).data.data
}
//download files
export const DownloadDocuments = async (data: GetItemQueryDTO) => {
  return await api.get(`${CREATE_DOCUMENT}${data.id}/download`, {
    headers: {
      Accept: 'application/octet-stream',
      'Content-Type': 'application/json',
      // "Content-Encoding":''
    },
  })
}
//search documents
export const SearchDocuments = async (data: GetFilesQueryDTO) => {
  return (
    await api.get<ServerRes<PaginatedData<GetFoldersResDTO>>>(
      useSanitizeQueryParams(
        `${SEARCH_DOCUMENTS}?page=${data.page}&query=${data.query}&folder=${data.folder}&startup=${data.startup}`
      )
    )
  ).data.data
}

/***
 *
 * Updates tab
 */
//get updates
export const GetUpdates = async (data: GetFilesQueryDTO) => {
  return (
    await api.get<ServerRes<PaginatedData<GetUpdatesResDTO>>>(
      useSanitizeQueryParams(
        `${GET_UPDATES}?page=${data.page}&query=${data.query}&startup=${data.startup}`
      )
    )
  ).data.data
}
//get updates comments
export const GetUpdatesComments = async (data: GetItemQueryDTO) => {
  return (
    await api.get<ServerRes<PaginatedData<GetUpdatesResDTO>>>(
      useSanitizeQueryParams(
        `${GET_UPDATES_COMMENTS}?page=${data.page}&update=${data.id}`
      )
    )
  ).data.data
}

//post updates
export const PostUpdates = async (data: PostUpdatesReqDTO) => {
  return await api.post<ServerRes<GetUpdatesResDTO>>(GET_UPDATES, data)
}

//post updates comments
export const PostUpdatesComment = async (data: PostUpdatesReqDTO) => {
  return await api.post<ServerRes<GetUpdatesResDTO>>(GET_UPDATES_COMMENTS, data)
}

//post updates media
export const PostUpdatesMedia = async (data: PostUpdatesMediaReqDTO) => {
  return await api.post<ServerRes<GetUpdatesResDTO>>(POST_UPDATES_MEDIA, data)
}
//delete update
export const DeleteUpdate = async (data: GetItemQueryDTO) => {
  return await api.delete<ServerRes<GetUpdatesResDTO>>(
    GET_UPDATES + data.id + '/'
  )
}
//toggle updates like
export const ToggleUpdatesLike = async (data: GetItemQueryDTO) => {
  return await api.post<ServerRes<GetUpdatesResDTO>>(
    GET_UPDATES + data.id + '/toggle-like/',
    {}
  )
}
//get update
export const GetUpdate = async (data: GetItemQueryDTO) => {
  return (
    await api.get<ServerRes<GetUpdatesResDTO>>(GET_UPDATES + data.id + '/')
  ).data.data
}

/***
 *
 * Access tab
 */
//get updates
export const GetAccessList = async (data: GetFilesQueryDTO) => {
  return (
    await api.get<ServerRes<PaginatedData<GetAccess>>>(
      useSanitizeQueryParams(
        `${GET_ACCESS_LIST}?page=${data.page}&query=${data.query}&startup=${data.startup}&status=${data.status}`
      )
    )
  ).data.data
}
export const GetUsersInvestors = async (data: GetUsersInvestorsQueryDTO) => {
  return (
    await api.get<ServerRes<PaginatedData<UsersInvestorsResDTO>>>(
      useSanitizeQueryParams(
        `${USERS_INVESTORS}?page=${data.page}&search=${data.search}&category=${data.category}`
      )
    )
  ).data.data
}

//delete access
export const DeleteAccessReq = async (data: GetItemQueryDTO) => {
  return await api.delete<ServerRes<GetAccess>>(GET_ACCESS_LIST + data.id + '/')
}
//update access to data room
export const UpdateAccessReq = async (data: GetItemQueryDTO) => {
  return await api.patch<ServerRes<GetAccess>>(
    GET_ACCESS_LIST + data.id + '/',
    { status: data.status }
  )
}
//grant access to data room
export const GrantAccessReq = async (data: GrantAccessQueryDTO) => {
  return await api.post<ServerRes<any>>(GET_ACCESS_LIST, { ...data })
}
